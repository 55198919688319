


































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import controller from '@/app/ui/controllers/PickupController'
import LoadingOverlay from '@/app/ui/components/LoadingOverlay/index.vue'
import { PickupDetail } from '@/domain/entities/Pickup'
import Button from '@/app/ui/components/Button/index.vue'
import DropdownSelect from '@/app/ui/components/DropdownSelect/index.vue'
import { EventBus, EventBusConstants } from '@/app/infrastructures/misc'
import IconPeople from '@/app/ui/assets/menu_icon_user_management.vue'
import IconCourier from '@/app/ui/assets/menu_icon_driver.vue'
import IconPhone from '@/app/ui/assets/icon_phone.vue'
import IconQuantity from '@/app/ui/assets/menu_icon_pickup.vue'
import { validationMixin } from 'vuelidate'
import { Validations } from 'vuelidate-property-decorators'
import { required, minValue } from 'vuelidate/lib/validators'

interface Form {
  quantity: number
  hubPersonName: string
  personName: string
}

@Component({
  mixins: [validationMixin],
  components: {
    LoadingOverlay,
    Button,
    DropdownSelect,
    IconPeople,
    IconCourier,
    IconPhone,
    IconQuantity,
  },
})
export default class MarkAsDonePage extends Vue {
  form: Form = {
    quantity: 0,
    hubPersonName: '',
    personName: '',
  }

  created(): void {
    controller.getPickupDetail(this.$route.params.shipmentId)

    EventBus.$on(EventBusConstants.PICKUP_MARK_AS_DONE_SUCCESS, () => {
      Vue.notify({
        title: 'Mark As Done',
        text: `Shipment Id ${this.$route.params.shipmentId} marked as done`,
        type: 'success',
        duration: 5000,
      })

      this.$router.push({
        name: this.previousRoute,
      })
    })
  }

  @Validations()
  validations(): Record<string, any> {
    return {
      form: {
        quantity: {
          required,
          minValue: minValue(1),
        },
        hubPersonName: {
          required,
        },
        personName: {
          required,
        },
      },
    }
  }

  get previousRoute(): string {
    return this.$route.name?.replace('MarkAsDone', '') || ''
  }

  get isLoading(): boolean {
    return controller.isLoading
  }

  get pickupDetail(): PickupDetail {
    return controller.pickupDetail
  }

  @Watch('pickupDetail')
  private pickupDetailUpdated(value: PickupDetail) {
    this.form.quantity = Number(value.quantity)
  }

  private submitMarkAsDone() {
    if (this.pickupDetail.histories) {
      controller.markAsDone({
        statusId: this.$route.meta.additional.statusId,
        groupId: this.pickupDetail.groupId,
        shipmentId: this.$route.params.shipmentId,
        courierId: Number(this.$route.params.courierId),
        relationId:
          this.$route.name === 'PickupCRROTWMarkAsDone' ||
          this.pickupDetail.groupId
            ? 'YBS'
            : undefined,
        ...this.form,
      })
    }
  }

  beforeDestroy(): void {
    EventBus.$off(EventBusConstants.PICKUP_MARK_AS_DONE_SUCCESS)
  }
}
